import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import set from 'lodash/set';
import { FormikProps } from 'formik';
import { faTimesCircle, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FormikNumberTextBox, FormikSelectBox, FormikTextBox, Button } from '../../shared';
import { FrequencyType, ExpenseType, Expense } from '../../../types/bank-statement';
import { toOptionModels } from '../../../utils/object-helper';
import { required, dollarRange, maxCharLength, noFullStops } from '../../shared/form/formik/validators';
import { FormValidatorField } from '../../shared/form/formik/formik-validator';
import { toCamelCase } from '../../../utils/string-helper';

interface ExplanationState {
  showExplanation: boolean
  showExplanationFreetext: boolean
  showAdditionalExplanation: boolean
  frequencies: FrequencyType[]
}

export interface ExpenseFormValues extends Expense { }

export interface ExpenseFormFieldsProps {
  formikProps: FormikProps<any>
  type: ExpenseType
  className?: string
  header?: string
  subHeader?: string
  headerImageSrc: string
  allowRemoval?: boolean
  onRemove?: (type: ExpenseType) => void
}

//Helpers for getting nested form values
const getFormName = (type: ExpenseType, name: keyof ExpenseFormValues) => `${toCamelCase(type)}.${name}`;

const getValue = (formikProps: FormikProps<any>, type: ExpenseType, name: keyof ExpenseFormValues) => {
  const nestedKey = getFormName(type, name);
  return get(formikProps.values, nestedKey);
}

export const frequencyOptions: FrequencyType[] = ['Weekly', 'Fortnightly', 'Monthly']
export const nonAccommodationLivingExpenses: ExpenseType[] = ['Utilities', 'Groceries', 'Communication', 'Transport', 'Bnpl'];


//determines if the explanation option should be shown
export const shouldShowExplanation = (type: ExpenseType, frequency: string, amount?: string) => {
  //'Other' panel should always have freetext. Never want explanation for insurance and childcare
  if (type === 'Insurance' || type === 'Childcare' || type === 'Other' || type === 'Bnpl') return false;

  if (amount === undefined || (amount as any) === '') return false;

  const numberAmount = Number(amount);
  const visibilityDictionary = {
    accommodation: [
      { frequency: 'None', predicate: () => numberAmount < 150 },
      { frequency: 'Weekly', predicate: () => numberAmount < 150 },
      { frequency: 'Fortnightly', predicate: () => numberAmount < 300 },
      { frequency: 'Monthly', predicate: () => numberAmount < 600 }
    ],
    nonAccommodation: [
      { frequency: 'None', predicate: () => numberAmount < 20 },
      { frequency: 'Weekly', predicate: () => numberAmount < 20 },
      { frequency: 'Fortnightly', predicate: () => numberAmount < 40 },
      { frequency: 'Monthly', predicate: () => numberAmount < 80 }
    ],
    communication: [
      { frequency: 'None', predicate: () => numberAmount < 10 },
      { frequency: 'Weekly', predicate: () => numberAmount < 10 },
      { frequency: 'Fortnightly', predicate: () => numberAmount < 20 },
      { frequency: 'Monthly', predicate: () => numberAmount < 40 }
    ],
    groceries: [
      { frequency: 'None', predicate: () => numberAmount < 80 },
      { frequency: 'Weekly', predicate: () => numberAmount < 80 },
      { frequency: 'Fortnightly', predicate: () => numberAmount < 160 },
      { frequency: 'Monthly', predicate: () => numberAmount < 320 }
    ]
  };

  const dictionaryToUse = type === 'Accommodation' ? visibilityDictionary.accommodation :
    type === 'Groceries' ? visibilityDictionary.groceries : 
    type === "Communication" ? visibilityDictionary.communication : 
    visibilityDictionary.nonAccommodation;

  const itemToCheck = dictionaryToUse.find(e => e.frequency === frequency);

  return !itemToCheck ? false : itemToCheck.predicate();
}

const getExplanationLabel = (type: ExpenseType) => `${type !== 'Groceries' ? 'Details' : 'Your declared grocery expense is less than would be expected. Please select the option that best explains why' }`;

//determine whether the freetext explanation should be shown
export const shouldShowFreetext = (type: ExpenseType, explanation: string | undefined, frequency: string, amount?: string) => {
  return type === 'Other' || (shouldShowExplanation(type, frequency, amount) && explanation === 'Other');
}

export const shouldShowAdditionalExplanation = (type: ExpenseType, explanation: string | undefined) => {
  if (type !== 'Groceries' || explanation === undefined) return false;
  return ['Paid for by partner', 'Shared household expense', 'Living with parents / family'].includes(explanation);
}

//if amount is 0 change potential frequency options to contain only 'none'
export const resolveFrequencyOptions = (amount?: string) => {
  const defaultOptions = frequencyOptions;
  if (amount === undefined || amount === '') return defaultOptions;
  if (Number(amount) === 0) return ['None'] as FrequencyType[];
  return defaultOptions;
}

export const expenseExplanations = {
  accomodation: ['Living with parents', 'Boarding', 'Own home outright', 'Centrelink deduction', 'Other'],
  utilities: ['Living with parents', 'Included in board', 'Included in rent', 'Other'],
  groceries: ['Other', 'Living with parents / family', 'Paid for by partner', 'Paid for by employer', 'Shared household expense', 'Included in board'],
  communication: ['Company phone', 'Family plan', 'Other'],
  transport: ['Company car', 'Car pool', 'Other']
}

export const getReasonsForType = (type: ExpenseType) : string[] => {
  switch (type) {
    case 'Accommodation':
      return [...expenseExplanations.accomodation];
    case 'Utilities':
      return [...expenseExplanations.utilities];
    case 'Groceries':
      return [...expenseExplanations.groceries];
      case 'Communication':
        return [...expenseExplanations.communication];
      case 'Transport':
        return [...expenseExplanations.transport];
    default:
      return [];
  }
}

//make sure selected frequency matches the current possible options
const ensureFrequencyValueMatchesOptions = (type: ExpenseType, values: any, options: FrequencyType[]) => {
  const frequencyKey = getFormName(type, 'frequency');
  const frequencyValue = get(values, frequencyKey);
  if (!frequencyValue || !options.some(e => e === frequencyValue)) set(values, frequencyKey, options[0]);
}

//Overrided required to take into account the allow removal property
//If the allow removal is true and any value is not undefined then this field is required
const requiredWithAllowRemoval = (type: ExpenseType, allowRemoval: boolean) => (value: any, values?: ExpenseFormValues) => {
  if (!allowRemoval) return required(value);
  const amountValue = get(values, getFormName(type, 'amount'));
  const freetextValue = get(values, getFormName(type, 'freetext'));

  if (amountValue === '') return null;

  const shouldCheckRequired = (amountValue !== undefined) && !freetextValue;
  if (shouldCheckRequired) {
    return required(value);
  }
  return null;
}

const explanationRequired = (type: ExpenseType) => (value: any, values?: ExpenseFormValues) => {
  const explanationValue = get(values, getFormName(type, 'explanation'));
  const frequencyValue = get(values, getFormName(type, 'frequency'));
  const amountValue = get(values, getFormName(type, 'amount'));
  const explanationShowing = shouldShowExplanation(type, frequencyValue, amountValue);

  if (explanationShowing && !getReasonsForType(type).some(e => e === explanationValue)) {
    return required(value);
  }

  return null;
}

const freetextRequired = (type: ExpenseType) => (value: any, values?: ExpenseFormValues) => {
  const currentValue = (values as any)![toCamelCase(type)] as ExpenseFormValues;
  const freetextShowing = shouldShowFreetext(type, currentValue.explanation, currentValue.frequency, currentValue.amount);

  //Other never
  //only validate the freetext if there is an amount value. This check is for the optional 'other' panel
  if (freetextShowing && currentValue.amount !== '' && currentValue.amount !== undefined) {
    return required(value);
  }

  return null;
}

const additionalExplanationRequired = (type: ExpenseType) => (value: any, values?: ExpenseFormValues) => {
  const explanationValue = get(values, getFormName(type, 'explanation'));
  const additionalExplanationShowing = shouldShowAdditionalExplanation(type, explanationValue);

  return additionalExplanationShowing && !value ? required(value): null;
}

export const getValidators = (type: ExpenseType, allowRemoval: boolean) => {
  const validators: FormValidatorField<any, any>[] = [
    { name: getFormName(type, 'amount'), validators: [requiredWithAllowRemoval(type, allowRemoval), dollarRange(0, 99999), noFullStops] },
    { name: getFormName(type, 'frequency'), validators: [requiredWithAllowRemoval(type, allowRemoval)] },
    { name: getFormName(type, 'explanation'), validators: [explanationRequired(type)] },
    { name: getFormName(type, 'freetext'), validators: [freetextRequired(type), maxCharLength(99)] },
    { name: getFormName(type, 'additionalExplanation'), validators: [additionalExplanationRequired(type)] }
  ];
  return validators;
}

const ExpenseFormFields: React.FC<ExpenseFormFieldsProps> = (props) => {
  const { header, type, subHeader, headerImageSrc, allowRemoval, onRemove, formikProps, className } = props;

  const currentValue = formikProps.values[toCamelCase(type)] as ExpenseFormValues;

  const [state, setState] = useState<ExplanationState>({
    showExplanation: shouldShowExplanation(type, currentValue.frequency, currentValue.amount),
    showExplanationFreetext: shouldShowFreetext(type, currentValue.explanation, currentValue.frequency, currentValue.amount),
    showAdditionalExplanation: shouldShowAdditionalExplanation(type, currentValue.explanation),
    frequencies: resolveFrequencyOptions(getValue(formikProps, type, 'amount')),
  });

  //Make sure the frequency value provided matches an option in the dropdown. If not then change it
  useEffect(() => {
    const frequencyKey = getFormName(type, 'frequency');
    const frequencyValue = get(formikProps.values, frequencyKey);

    //We have a mismatch so update frequencies
    if (!frequencyValue || !state.frequencies.some(e => e === frequencyValue)) {
      const newValues = { ...formikProps.values };
      set(newValues, frequencyKey, state.frequencies[0]);
      formikProps.setValues(newValues);
    }
  }, []);

  const handleFrequencyChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const values = { ...formikProps.values };
    const frequencyKey = getFormName(type, 'frequency');
    set(values, frequencyKey, event.currentTarget.value);
    handleAmountOrFrequencyChange(values);
  }

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const values = { ...formikProps.values };
    set(values, getFormName(type, 'amount'), event.currentTarget.value);
    handleAmountOrFrequencyChange(values, true);
  }

  const handleAmountOrFrequencyChange = (values: any, dontChangeShowExplanation?: boolean) => {
    const newState = { ...state };
    const newFormValues = { ...values };

    //Need the nested keys to safely set and get value
    const freetextKey = getFormName(type, 'freetext');
    const explanationKey = getFormName(type, 'explanation');
    const amountKey = getFormName(type, 'amount');
    const additionalExplanationKey = getFormName(type, 'additionalExplanation');

    const currentValue = newFormValues[toCamelCase(type)] as ExpenseFormValues;

    const showExplanation = shouldShowExplanation(type, currentValue.frequency, currentValue.amount);
    const showFreetext = shouldShowFreetext(type, currentValue.explanation!, currentValue.frequency, currentValue.amount) && showExplanation;
    const showAdditionalExplanation = shouldShowAdditionalExplanation(type, currentValue.additionalExplanation) && showExplanation;

    if (!dontChangeShowExplanation && state.showExplanation !== showExplanation) {
      newState.showExplanation = showExplanation;
      newState.showExplanationFreetext = showFreetext;
    }

    if (state.showAdditionalExplanation !== showAdditionalExplanation) {
      newState.showAdditionalExplanation = showAdditionalExplanation;
    }

    if (!state.showExplanation) set(newFormValues, explanationKey, undefined);
    if (!state.showExplanationFreetext) set(newFormValues, freetextKey, undefined);
    if (!state.showAdditionalExplanation) set(newFormValues, additionalExplanationKey, undefined);

    newState.frequencies = resolveFrequencyOptions(get(newFormValues, amountKey));
    ensureFrequencyValueMatchesOptions(type, newFormValues, newState.frequencies);

    setState(newState);
    formikProps.setValues(newFormValues);
    formikProps.validateForm();
  }

  const handleExplanationChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const values = { ...formikProps.values };
    const additionalExplanationKey = getFormName(type, 'additionalExplanation');

    set(values, getFormName(type, 'explanation'), event.currentTarget.value);

    if (!state.showAdditionalExplanation) set(values, additionalExplanationKey, undefined);

    const currentValue = values[toCamelCase(type)] as ExpenseFormValues;
    setState({ ...state,
      showExplanationFreetext: shouldShowFreetext(type, currentValue.explanation, currentValue.frequency, currentValue.amount),
      showAdditionalExplanation: shouldShowAdditionalExplanation(type, currentValue.explanation) });
  }

  const handleAdditionalExplanationChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const values = { ...formikProps.values };
    set(values, getFormName(type, 'additionalExplanation'), event.currentTarget.value);
  }

  const updateShowExplanation = () => {
    const newFormValues = { ...formikProps.values };
    const explanationKey = getFormName(type, 'explanation');
    const currentValue = newFormValues[toCamelCase(type)] as ExpenseFormValues;

    const showExplanation = shouldShowExplanation(type, currentValue.frequency, currentValue.amount);
    const showFreetext = shouldShowFreetext(type, currentValue.explanation, currentValue.frequency, currentValue.amount) && showExplanation;

    if (state.showExplanation !== showExplanation) {
      setState({ ...state, showExplanation: showExplanation, showExplanationFreetext: showFreetext });
    }

    if (!showExplanation) set(newFormValues, explanationKey, undefined);
  }

  const classes = classNames({
    'expense-panel': true,
    [className!]: className !== undefined
  });

  return (
    <div className={classes} data-test={`expense-panel-${type}`}>
      <div className='expense-top-container'>
        <div className="expense-panel-header">
          <i className="expense-panel-header-image"><img alt='expense-type' src={headerImageSrc} /></i>
          <div className="expense-panel-header-title-container">
            <div className="expense-panel-title">{header || type}</div>
            <div className="expense-panel-subtitle">{subHeader}</div>
          </div>
        </div>
        <div className="expense-panel-amount-and-frequency">
          <FormikNumberTextBox faIconPrefix={faDollarSign} name={getFormName(type, 'amount')} step={10} formikProps={formikProps} onChange={(e) => handleAmountChange(e)} onBlur={() => updateShowExplanation()} data-test={`expense-amount-${type}`} />
          <FormikSelectBox name={getFormName(type, 'frequency')} id="expense-panel-frequency" formikProps={formikProps} onChange={(e) => handleFrequencyChange(e)} options={toOptionModels(state.frequencies, true)} data-test={`expense-frequency-${type}`} />
        </div>
      </div>
      {state.showExplanation && (
        <div className='expense-panel-explanation'>
          <FormikSelectBox name={getFormName(type, 'explanation')} id="expense-panel-explanation" label={getExplanationLabel(type)} emptyFirstOption formikProps={formikProps} onChange={(e) => handleExplanationChange(e)} options={toOptionModels(getReasonsForType(type), true)} data-test={`expense-explanation-${type}`} />
        </div>
      )}
      {state.showExplanationFreetext && (
        <div className='expense-panel-explanation'>
          <FormikTextBox name={getFormName(type, 'freetext')} id="expense-panel-freetext" placeholder='Provide more information' onChange={formikProps.handleChange} formikProps={formikProps} data-test={`expense-freetext-${type}`} />
        </div>
      )}
      {state.showAdditionalExplanation && (
        <div className='expense-panel-explanation'>
          <FormikSelectBox name={getFormName(type, 'additionalExplanation')} id="expense-panel-additionalExplanation"
            label='Is money transferred to you to cover your grocery expense?' emptyFirstOption emptyOptionLabel='Please select' formikProps={formikProps}
            onChange={(e) => handleAdditionalExplanationChange(e)} options={toOptionModels(['Yes', 'No'])} data-test={`expense-additionalExplanation-${type}`} />
        </div>
      )}
      {allowRemoval && (
        <div className='expense-panel-remove-button-container'>
          <Button id='expense-panel-remove-button' variant='pill' size='small' text='Remove' onClick={() => onRemove!(type)} faIcon={faTimesCircle} iconPlacement="before" data-test={`remove-button-${type}`} />
        </div>
      )}
    </div>
  );
};

export default ExpenseFormFields;